import { FiFolder } from 'react-icons/fi'

import ThumbZoomable from '~/components/ThumbZoomable'
import { TagNewItem } from '~/modules/retailMedia/components/TagNewItem'

import * as S from './styles'

const AdInfo: RowComponent = data => {
  const logoUrl =
    data.campaign_settings.assets?.[0]?.url || data.campaign_settings.logo_url

  return (
    <S.TitleWrapper>
      {data.ad_type === 'sponsored_brand' && (
        <>
          <ThumbZoomable
            url={logoUrl}
            title={data.campaign_settings.brand_name}
          />

          <div className="mainInfo">
            {data?.campaign_settings?.headline && (
              <strong className="title">
                {data.campaign_settings.headline}
              </strong>
            )}

            {data?.campaign_settings?.description && (
              <>
                <span className="description">
                  {data.campaign_settings.description}
                </span>
                <br />
              </>
            )}

            <span className="description" title="Data de criação">
              <TagNewItem created={data.created_at} />
            </span>
          </div>
        </>
      )}

      {data.ad_type === 'product' && (
        <>
          <ThumbZoomable url={data.image_url} title={data.name} />

          <div className="mainInfo">
            {!!data?.categories?.length && (
              <div className="text-sm text-gray">
                <span
                  className="d-inline-flex me-1"
                  style={{ transform: 'translateY(1px)' }}
                >
                  <FiFolder />
                </span>
                <span>{data?.categories?.at(-1)}</span>
              </div>
            )}

            <strong className="title">{data.name}</strong>
            <span className="description">SKU: {data.product_sku}</span>
            <br />
            <span className="description" title="Data de criação">
              <TagNewItem created={data.created_at} />
            </span>
          </div>
        </>
      )}

      {data.ad_type === 'banner' && (
        <>
          <ThumbZoomable
            url={data.settings.media_url}
            title={data.media_size}
          />

          <div className="mainInfo">
            <small>
              <strong>Formato:</strong> {data.settings.ad_size}
            </small>
            <br />
            <span className="description" title="Data de criação">
              <TagNewItem created={data.created_at} />
            </span>
          </div>
        </>
      )}
    </S.TitleWrapper>
  )
}

export default AdInfo
