import React from 'react'

import ThumbZoomable from '~/components/ThumbZoomable'
import { isPublisher, isReadOnly } from '~/helpers'
import { TagNewItem } from '~/modules/retailMedia/components/TagNewItem'

import ReviewActions from './ReviewActions'

import * as S from './styles'

const AdInfo: RowComponent = data => {
  const logoUrl =
    data.campaign_settings.assets?.[0]?.url || data.campaign_settings.logo_url

  return (
    <S.TitleWrapper>
      {data.ad_type === 'sponsored_brand' && (
        <>
          <ThumbZoomable
            url={logoUrl}
            title={data.campaign_settings.brand_name}
          />

          <div className="mainInfo">
            {data?.campaign_settings?.headline && (
              <strong className="title">
                {data.campaign_settings.headline}
              </strong>
            )}

            {data?.campaign_settings?.description && (
              <>
                <span className="description">
                  {data.campaign_settings.description}
                </span>
                <br />
              </>
            )}

            <span className="description" title="Data de criação">
              <TagNewItem created={data.created_at} />
            </span>
          </div>
        </>
      )}

      {data.ad_type === 'banner' && (
        <>
          <ThumbZoomable
            url={data.settings.media_url}
            title={data.media_size}
            size={data.status === 'pending_review' ? 'medium' : 'small'}
          />

          <div className="mainInfo">
            <small>
              <strong>Formato:</strong> {data.settings.ad_size}
            </small>

            <br />

            <span className="description" title="Data de criação">
              <TagNewItem created={data.created_at} showBadgeNew={false} />
            </span>

            {data.status === 'pending_review' &&
              isPublisher &&
              !isReadOnly() && <ReviewActions adId={data.id} />}
          </div>
        </>
      )}
    </S.TitleWrapper>
  )
}

export default AdInfo
