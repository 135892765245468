import styled, { css } from 'styled-components'

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 22rem;

    .mainInfo {
      line-height: 1;
      min-width: 200px;

      .title {
        margin-bottom: ${theme.spacings.tiny};
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: ${theme.colors.primary};
        font-size: ${theme.font.sizes.xsmall};
        overflow-wrap: anywhere;
      }

      .description {
        font-size: ${theme.font.sizes.xsmall};
        display: inline-flex;

        > div {
          margin-bottom: 0;
        }
      }
    }
  `}
`
